// import { TimePicker, DatePicker } from "@mui/lab";
// import { DatePicker } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { categories } from 'data/categories';
import {
  Grid, Box,
  styled, Button
} from "@mui/material";

import { useState } from "react";
import { SimpleCard } from '..';

const initialStructure = { categories: ['Announcements'], text: '', title: '', date_time: new Date(), active: true}


const BlogSettingsForm = ({ onContentChange, blog }) => {

  const [selectedDate, setSelectedDate] = useState(blog.date_time)
  const [category, setCategory] = useState(blog.categories);
  const [categoryValue, setCategoryValue] = useState('');


  function handleDateChange(date) {

    const updatedDate = {
      target: {
        name: 'time',
        value: date
      }
    }

    onContentChange(updatedDate);
    setSelectedDate(date)
    // persist();
    // setEditedState(true)
  }


  function handleCategoryChange(data) {

    const updatedArr = {
      target: {
        name: 'categories',
        value: data
      }
    }

    onContentChange(updatedArr);
  }


  

  return (
    <SimpleCard title={
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
        <span>Blog Settings</span>
        <Switch
          name="active"
          checked={blog.active}
          onChange={onContentChange}
          inputProps={{ 'aria-label': 'controlled' }}
          color="primary" sx={{ position: "relative", top: "-5px" }} /></Box>
    }>
      <Grid container spacing={0}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <TextField
            sx={{ m: '10px 5px', width: '100%' }}
            type="text"
            name="title"
            id="blog-title"
            onChange={onContentChange}
            errorMessages={["This field is required"]}
            label="Title (Min length 10, Max length 80)"
            validators={["required", "minStringLength: 4", "maxStringLength: 9"]}
            defaultValue={blog.title}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            sx={{ m: '10px 5px', width: '100%' }}
            type="text"
            name="text"
            id="blog-text"
            defaultValue={blog.text}
            onChange={onContentChange}
            errorMessages={["This field is required"]}
            label="Description (Min length 10, Max length 140)"
            validators={["required", "minStringLength: 10", "maxStringLength: 140"]}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            sx={{ m: '20px 0' }}
            multiple
            name="categories"
            value={category}

            options={categories}
            getOptionLabel={(option) => option}
            defaultValue={[categories[0]]}

            inputValue={categoryValue}
            onInputChange={(event, newInputValue) => {
              setCategoryValue(newInputValue);

            }}
            onChange={(event, newValue) => {
              setCategory(newValue);
              handleCategoryChange(newValue);

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Multiple values"
                placeholder="Category"
                fullWidth
              />
            )}></Autocomplete>

        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="time"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(props) => (<TextField
                {...props}
                fullWidth
                variant="standard"
                id="mui-pickers-date"
                label="Date picker"
              />
              )
              }
            />
          </LocalizationProvider>

        </Grid>
        {/* 
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormControlLabel
          name="active"
          onChange={onContentChange}
          checked={blog.active}
          control={<Switch color="primary" />}
          // label={    <Typography >Active</Typography>}
          labelPlacement="start"
          sx={{margin: 0, pt: "15px"}}
        />
      </Grid> */}
      </Grid>
    </SimpleCard>
  );
};

export default BlogSettingsForm;
