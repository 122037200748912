import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../auth/authRoles';
import UpdateBlog from 'app/views/blogs/UpdateBlog';

const Blogs = Loadable(lazy(() => import('../views/blogs/Blogs')));
const AddBlog = Loadable(lazy(() => import('../views/blogs/AddBlog')));

const blogsRoutes = [
  { path: '/blogs', element: <Blogs />, auth: authRoles.admin },
  { path: '/blogs/add', element: <AddBlog />, auth: authRoles.admin },
  { path: '/blogs/edit/:blogId', element: <UpdateBlog />, auth: authRoles.admin },
];

export default blogsRoutes;
