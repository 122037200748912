import axios from 'axios.js'
import { useState, useEffect, useCallback } from 'react'
import { Breadcrumb, SimpleCard } from "app/components";
import { useNavigate, useParams } from "react-router-dom";
import BlogSettingsForm from "app/components/forms/BlogSettingsForm";
import BlogsEditor from "app/components/visualEditor/BlogsEditor";
import { Grid, Box, Button, Fab, Icon, styled, Link } from '@mui/material';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import BlogPreview from 'app/components/previews/BlogPreview';
import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LensTwoTone } from '@mui/icons-material';


const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));



let currentDate = new Date();
const initialBlogValues = {
    id: 0,
    title: "",
    time: moment(currentDate).format('LL'),
    categories: ["Announcements"],
    img_src: "",
    text: "",
    html: '...',
    date_time: currentDate,
    active: true
}


const UpdateBlog = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [open, setOpen] = useState(false);

    const blogId = params.blogId || undefined;

    const [openSuccessAlert, setSuccessAlert] = useState(false);
    const [openInfoMessage, setInfoMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const [successAlertMsg, setSuccessAlertMsg] = useState("");

    const handleAlertOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSuccessClose = () => {
        setSuccessAlert(false);
        navigate("/blogs");
    }

    const handleInfoClose = () => setInfoMessage("");
    const [blog, setBlog] = useState(initialBlogValues);


    const getSavedLink = () => {


        let errors = []

        let imageElements = document.querySelector('.blog-preview').getElementsByTagName('img')

        let firstImage = imageElements.length > 0 ? imageElements[0].src : errors.push('Please, add some images to the blog')

        return { firstImage: firstImage, errors: errors }

    }

    const handleNotLoadedImages = async (id, html) => {

        let firstImage = ''

        var counter = 0
        let errors = []

        while (html.includes('"blob:')) {


            let blobLink = html.match(/blob:[^"]*/i)[0];

            if (blobLink) {

                let file = await fetch(blobLink).then(r => r.blob()).then(blobFile => new File([blobFile], "YOUNET_IMG" + counter, { type: blobFile.type }))

                var formData = new FormData();
                formData.append("blog-image", file);


                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };


                let newUrl = '';
                await axios.post('/api/content/media/image/upload', formData, config).then((res) => {
                    newUrl = res.data.data.url || '';
                }).catch(e => {
                    console.error(e)
                    errors.push('Something went wrong when tried to upload your image on the server. Please, try again later')
                });

                let updateHtml = html.replace(blobLink, 'https://admin.younet.ai/static/images/blogs/' + newUrl)
                html = updateHtml;

                if (counter == 0) {
                    firstImage = newUrl;
                }

            } else {
                errors.push('Please, add some images to the blog')
                break;
            }

            counter++;
        }


        return { html: html, firstImage: 'https://admin.younet.ai/static/images/blogs/' + firstImage, errors: errors };
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let data = blog.html.includes("blob:") ? await handleNotLoadedImages(blog.id, blog.html) : getSavedLink();

        if (data.errors.length > 0) {
            setOpen(false)
            setInfoMessage(data.errors[0])
            return
        }

        const result = { 
            id: blog.id,
            active: blog.active,
            categories: blog.categories.join(),
            img_src: data.firstImage,
            html: data.html ?? blog.html,
            text: blog.text,
            title: blog.title,
            url: blog.url,
            time: blog.time,
            date_time: blog.date_time
        }

        await axios.put('/api/content/blogs/updateBlog', { blog: result }).then((res) => {
            setSuccessAlertMsg(res.data.msg)
            setOpen(false)
            setSuccessAlert(true)

        }).catch((err) => {
            setSuccessAlertMsg(err.msg)
            setOpen(false)
            setSuccessAlert(true)
        });


    };

    const handleEditorChange = useCallback((data) => {
        setBlog({ ...blog, ['html']: data });
    })

    const handleContentChange = (event) => {

        let value = event.target.value;
        if (event.target.name == 'time') {
            setBlog({ ...blog, ['date_time']: value.toISOString() });
            value = moment(value).format('LL');
        } else if (event.target.name == 'active') {
            value = event?.target?.checked;
        } else if (event.target.name == 'categories') {
        } else {
            event.persist();
        }

        // setEditedState(true)
        setBlog({ ...blog, [event.target.name]: value });
    };


    useEffect(() => {

        const fetchBlog = async () => {
            await axios.post('/api/content/blogs/getBlog', { id: blogId }).then((res) => {
                let blog = res?.data?.blog || {};
                blog.categories = blog.categories?.split(',') || [];


                setBlog(blog);
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                console.error(err)
            });
        }


        blogId && fetchBlog();
    }, []);

    return (
        <Container>
            <ValidatorForm
                onSubmit={handleAlertOpen} onError={(err) => console.error(err)}
            >
                {!loading &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box className="breadcrumb" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Breadcrumb routeSegments={[{ name: "Blogs", path: "/blogs/edit" }, { name: "Edit" }]} />
                                <Button type="submit" onClick={handleAlertOpen} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <SimpleCard title="Editor">
                                <BlogsEditor initialStructure={blog.html} onEditorChange={handleEditorChange} />
                            </SimpleCard>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlogSettingsForm blog={blog} onContentChange={handleContentChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <SimpleCard title="Preview">
                                <BlogPreview blog={blog} description={blog.text} />
                            </SimpleCard>
                        </Grid>

                    </Grid>
                }
            </ValidatorForm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure you want to save this blog?</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Saving this blog will automatically publish it to the <b><i><Link><a href={`https://younet.ai/blogs/details/${blog.url}`} >https://younet.ai/blogs/details/{blog.url}</a></Link></i></b> page.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Back
                    </Button>

                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSuccessAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{successAlertMsg}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleSuccessClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openInfoMessage != ''}
                onClose={handleInfoClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{openInfoMessage}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleInfoClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>

    )

}


export default UpdateBlog;