import { CKEditor } from '@ckeditor/ckeditor5-react';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './blogsEditor.css'
import Editor  from 'ckeditor5-custom-build/build/ckeditor';



function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {

        loader.file.then((file) => {

          resolve({
            default: URL.createObjectURL(file)
          });

        });
      });
    }
  };
}

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


const editorConfiguration = {
  extraPlugins: [uploadPlugin],
  mediaEmbed: {
    toolbar: [
      'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
      'toggleImageCaption', 'imageTextAlternative'
    ]
    
  },
  mediaEmbed: {
    previewsInData: true
  }
};



const BlogsEditor = ({ onEditorChange, initialStructure = '...' }) => {

  return (

    <div className="blog-editor">
      <div className="blog-editor__toolbar"></div>
      <div className="blog-editor__editable-container">
        <CKEditor
          onReady={editor => {
            window.BlogsEditor = editor;

            const toolbarContainer = document.querySelector('.blog-editor__toolbar');

            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            onEditorChange(data)
          }}

          editor={Editor}
          data={initialStructure}
          config={editorConfiguration}
        />
      </div>
    </div>
  );

}




export default BlogsEditor;