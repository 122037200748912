import parse from 'html-react-parser';
import './blogsPreview.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const BlogPreview = (content) => {


  const { title, html, categories, time, img_src } = content.blog

  let formattedCategories = typeof categories == "string" ? categories.join(",") : categories;

  return (
    <>
      <h1 className='blog-title'>{title}</h1>
      <div className='blog-divider'></div>
      <div className='blog-time'>
        <CalendarMonthIcon />
        <p style={{ padding: "0px 5px", margin: "0" }} >{time}</p><b style={{fontSize : 16, padding : '0 10px' }}>{formattedCategories.map((key,index) => (<a key={index}>{key}</a>))}</b></div>
      {/* <div>{description}</div> */}
      <div className='blog-preview ck-content'> {html && parse(html)}</div>
    </>
  );
}


export default BlogPreview;